@layer base {
    button,
    textarea,
    input,
    select,
    div,
    a{
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-tap-highlight-color: transparent;

    }
}

@layer components {
    .label {
        @apply text-sm font-medium py-1 px-2 rounded bg-gray-200 text-gray-800 font-bold align-middle;
    }

    .label.warning {
        @apply bg-yellow-200 text-yellow-800;
    }

    .label.info {
        @apply bg-blue-200 text-blue-800;
    }

    .label.error {
        @apply bg-red-200 text-red-800;
    }

    .label.success {
        @apply bg-green-200 text-green-800;
    }

    .box {
        @apply border rounded shadow-sm p-3 mb-3;
    }

    .box-active,
    .box-hover:hover,
    .box-hover:focus,
    .box-hover:focus-within,
    select:hover {
        @apply border-gray-400 shadow-sm;
    }

    select {
        @apply border rounded appearance-none bg-white shadow-sm p-4;
    }

    select.center {
        @apply text-center;
        text-align-last: center;
    }

    textarea:focus-within ~ label,
    textarea:not(:placeholder-shown) ~ label,
    input:focus-within ~ label,
    input:not(:placeholder-shown) ~ label {
        @apply transform scale-75 -translate-y-6;
    }

    textarea:focus-within ~ label,
    input:focus-within ~ label {
        @apply text-blue-500;
    }

    .outline textarea:focus-within ~ label,
    .outline textarea:not(:placeholder-shown) ~ label,
    .outline input:focus-within ~ label,
    .outline input:not(:placeholder-shown) ~ label {
        @apply transform scale-75 -translate-y-4 z-0 ml-3 px-1 py-0;
    }

    @keyframes slideInFromTop {
        0% {
            transform: translateY(-100%);
        }
        100% {
            transform: translateY(0);
        }
    }

    .toast {
        animation: 200ms ease-out 0s 1 slideInFromTop;
    }


    .btn {
        @apply font-display text-base py-2 px-4 rounded flex items-center gap-1 tracking-tight;
    }

    .btn:hover {
        @apply bg-gray-50;
    }

    @screen md {
        .btn {
            @apply max-w-xs;
        }
    }

    .btn-primary {
        background-color: #ea560d;
        @apply text-white;
    }

    .btn-primary:hover {
        background-color: #C44233;
    }

    .btn-primary:focus {
        background-color: #ea560d;
    }


    @-webkit-keyframes AnimationName {
        0%{background-position:0% 50%}
        50%{background-position:100% 50%}
        100%{background-position:0% 50%}
    }
    @-moz-keyframes AnimationName {
        0%{background-position:0% 50%}
        50%{background-position:100% 50%}
        100%{background-position:0% 50%}
    }
    @keyframes AnimationName {
        0%{background-position:0% 50%}
        50%{background-position:100% 50%}
        100%{background-position:0% 50%}
    }

    .btn-blue {
        @apply bg-blue-500 text-white;
    }
    .btn-blue:hover {
        @apply bg-blue-700;
    }

    .btn-whatsapp {
        @apply text-white;
        background-color: #128C7E;
    }

    .btn-whatsapp:hover {
        background-color: #075E54;
    }


    .btn-facebook {
        @apply text-white;
        background-color: #4267B2;
    }

    .btn-facebook:hover {
        @apply bg-blue-800;
    }

    .btn-gray {
        @apply bg-gray-300 text-gray-800;
    }
    .btn-gray:hover {
        @apply bg-gray-400;
    }

    .btn-red {
        @apply bg-red-300 text-red-800;
    }
    .btn-red:hover {
        @apply bg-red-400;
    }

    .btn-filters {
        @apply flex justify-between items-center cursor-pointer rounded shadow px-2 py-1 text-sm hover:shadow-md whitespace-nowrap space-x-1;
    }

    .modal-content {
        @apply inset-0;
    }

    .ql-align-center {
        @apply text-center;
    }

    .ql-align-right {
        @apply text-right;
    }

    .ql-align-justify {
        @apply text-justify;
    }

    .ql-align-left {
        @apply text-left;
    }

    .ql-size-small {
        @apply text-xs;
    }

    .ql-size-large {
        @apply text-lg;
    }

    .ql-size-huge {
        @apply text-2xl;
    }

    .ql-size-normal {
        @apply text-base;
    }

    .ql-xabalas {
        @apply text-4xl;
    }

    .ql-ident-1 {
        @apply pl-4;
    }

    .ql-ident-2 {
        @apply pl-8;
    }

    .ql-ident-3 {
        @apply pl-12;
    }

    .ql-ident-4 {
        @apply pl-16;
    }

    @screen md {
        .modal-content {
            bottom: auto;
            right: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
