@import "@byma/shared/styles/components";
@tailwind base;

/* Write your own custom base styles here */
html,
body,
#__next {
    height: 100%;
}

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Write your own custom component styles here */

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

/* Your own custom utilities */
